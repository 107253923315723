/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */
.App {
  display: block;
  position: relative;
  text-align: center;
  background: #efeef3;
}
.Navigation-Bar {
  display: flex;
  position: sticky;
  position: -webkit-sticky;
  top: 0; /* required */
  z-index: 1;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background: #8776E8;
}
.Footer-Bar {
  display: block;
  position: relative;
  text-align: left;
  align-items: center;
  width: 100%;
  height: 124px;
  background: #8776E8;
}
.App-Legal-Text {
  display: block;
  position: relative;
  text-align: left;
  /* background: #efeef3; */
  width: 70%;
  left: 15%;
  border-radius: 25px;
  border: 0px solid #d8deef;
}
.Banner {
  /* display: flex; */
  position: relative;
  text-align: left;
  align-items: baseline;
  width: 100%;
  height: 80vh;
  background: #8776E8;
}
.Available-Languages-Section {
  display: block;
  /* margin-bottom: -2vh; */
  position: relative;
  text-align: center;
  align-items: center;
  width: 80%;
  left: 10%;
  height: 25vh;
  border-radius: 25px;
  border: 0px solid #d8deef;
  /* height: 124px; */
  background: #e7e5ef;
}
.Features-Section {
  display: block;
  position: relative;
  text-align: center;
  align-items: center;
  width: 80%;
  left: 10%;
  height: 45vh;
  border-radius: 25px;
  border: 0px solid #d8deef;
  /* height: 124px; */
  background: #e7e5ef;
}

@media (prefers-reduced-motion: no-preference) {
  .App-Logo {
    animation: App-logo-spin infinite 30s linear;
  }
}

@media screen and (max-width: 1040px) {
  .Navigation-Bar {
    height: 140px;
    text-align: center;
  }
  .Navigation-Bar .Navigation-Bar-Button {
    /* display: inline-block; */
    align-self: flex-end;
    margin-left: 12%;
    margin-right: -12%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    text-align: center;
    /* padding: 8px 12px; */
    font-size: 12px;
    font-weight: bold;
  }
  .Navigation-Bar .Navigation-Bar-Title {
    display: inline-block;
    align-self: flex-start;
    margin-top: 30px;
    font-size: 20px;
  }
  .Banner {
    height: 60vh;
  }
  .App-Logo {
    display: inline-block;
    align-self: flex-start;
    margin-top: 20px;
  }
  .CTA-Button {
    display: inline-block;
    align-self: flex-start;
    margin-top: 30px;
    font-size: 20px;
  }
  .Available-Languages-Section {
    height: 42vh;
  }
  .Features-Section {
    height: 55vh;
  }
  .Feature-Section-Tags .Feature-Section-Tag {
    width: 44%;
    height: 20%;
  }
  .Footer-Bar-Mail {
    font-size: 1.5vh;
  }
}

.App-Logo {
  display: flex;
  position: absolute;
  left: 2vmin;
  /* top: 20px; */
  height: 10vmin;
  pointer-events: none;
}

.Navigation-Bar-Title {
  display: flex;
  position: absolute;
  text-align: left;
  left: 14vmin;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}

.Navigation-Bar-Button {
  /* position: absolute; */
  text-align: right;
  /* right: 22vmin; */
  /* margin-left: 3%;
  margin-right: -3%; */
  bottom: 90px;
  background-color: transparent;
  /* border-radius: 25px; */
  padding: 8px 12px;
  border: 0px dotted #ccc;
  color: white;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

.CTA-Button {
  /* position: absolute; */
  text-align: right;
  right: 20px;
  bottom: 90px;
  margin-right: 20px;
  background-color: rgb(234, 197, 10);
  border-radius: 25px;
  padding: 8px 12px;
  border: 6px rgb(234, 197, 10);
  color: rgb(255, 255, 255);
  font-size: 2vh;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

.Navigation-Bar-Spacer {
  flex-grow: 1;
}

.Footer-Bar-Mail {
  /* position: absolute; */
  /* display: inline-block; */
  align-self: flex-end;
  text-align: center;
  right: 0px;
  bottom: 90px;
  background-color: transparent;
  border-radius: 25px;
  padding: 8px 12px;
  border: 0px dotted #ccc;
  color: white;
  font-size: 2vh;
  font-weight: bold;
  outline: none;
  cursor: pointer;
}

.Banner-Title {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 4vmin;
  font-size: 5vmin;
  line-height: 0.4;
  color: #ffffff;
}

.Banner-Subtitle {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 2vmin;
  font-size: 3vmin;
  line-height: 0.4;
  color: #e3d90e;
}

.Banner-Layer-Front {
  position: absolute;
  height: 60%;
  bottom: 0%;
  /* left: 10%; */
  width: 100%;
  object-fit: contain;
  /* background-size: 80%;
  background-repeat: no-repeat;
  background-image: -webkit-image-set(
    url("./assets/Banner.Layer.Front.png") 1x,
    url("./assets/Banner.Layer.Front.png") 2x);
  background-image: image-set(
    url("./assets/Banner.Layer.Front.png") 1x,
    url("./assets/Banner.Layer.Front.png") 2x); */
  /* transform: translate(-50%, 0px); */
}

.Banner-Layer-Background-1 {
  position: absolute;
  top: 25%;
  left: 0;
  width: 50%;
  height: 60%;
  object-fit: contain;
}

.Banner-Layer-Background-2 {
  position: absolute;
  top: 30%;
  right: 0;
  width: 50%;
  height: 60%;
  object-fit: contain;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
.App-header {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  height: 144px;
  background: #8776E8;
}

.Feature-Section-Tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  /* text-align: left; */
  width: 100%;
}

.Feature-Section-Tag {
  /* min-width: 50px;
  max-width: 200px; */
  width: 22%;
  height: 20%;
  text-align: left;
  align-items: center;
  display: flex;
  /* margin: auto; */
}

.Feature-Section-Tag-Icon {
  width: 20%;
  height: 50px;
  text-align: left;
  align-items: center;
  display: flex;
  /* bottom: 50%;
  transform: translate(0px, -50%); */
  /* margin: auto; */
}

.Feature-Section-Tag-Icon-Container {
  position: absolute;
  height: 6vmin;
  width: 6vmin;
  border-radius: 2vmin;
  border: 0px solid #d8deef;
  mix-blend-mode: multiply;
  background: #8776E8;
}

.Feature-Section-Tag-Icon-Image {
  position: absolute;
  height: 4vmin;
  width: 4vmin;
  /* margin-top: 0.1vmin; */
  margin-left: 1.0vmin;
  filter: brightness(0) invert(1);
}

.Feature-Section-Tag-Title {
  text-align: left;
  margin-left: 1.5vw;
  font-size: 2.5vmin;
  font-weight: bold;
  color: #030b1d;
  width: 100%;
}

.Section-Header {
  flex-grow: 1;
  font-size: 5vh;
  font-weight: bold;
  color: #3f3480;
}

.Section-Description {
  /* display: flex; */
  position: relative;
  /* top: 30px; */
  margin-left: 10%;
  margin-right: 10%;
  flex-grow: 1;
  text-align: center;
  color: #000000;
}

.Section-Padding {
  height: 20px;
}

.Container-Padding {
  height: 50px;
}

.App-main-banner {
  position: absolute;
  left: 0px;
  right: 0px;
  /* top: 144px; */
  /* object-fit: cover; */
  width: 100%;
  height: 536px;

  background: #8776E8;
}

.AppStore-CTA-Button {
  position: absolute;
  text-align: center;
  /* width: 100%; */
  top: 18vmin;
  left: 50%; 
  width: 120px;
  height: 44px;
  transform: translate(-60px, 0);
  background-color: transparent;
  border-radius: 25px;
  padding: 8px 12px;
  border: 0px dotted #ccc;
  color: white;
  font-size: 2vh;
  outline: none;
  cursor: pointer;
}

.AppStore-CTA-Button-Image {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  object-fit: cover;
  height: 100%;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
